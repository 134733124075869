import { WarningModalBox } from "../Styles/Modal-Styles";



function WarningModal({message}) {
    return(
        <>
            <WarningModalBox>
                <div className="modal_box">
                    <div className="box_inner">
                        <div className="icon">
                            <img src="/images/warning.gif" alt="warning" />
                        </div>
                        <div className="message">
                            <p>{message}</p>
                        </div>
                    </div>
                </div>
            </WarningModalBox>
        </>
    );
}


export default WarningModal;