import React, { useContext, useEffect, useState } from 'react';
import { DashboardWrapper } from "../Styles/Dashboard-Style";
import { Link } from 'react-router-dom';
import BookingData from '../Charts/WeeklyBooking-Chart';
import TicketData from '../Charts/TicketsData-Chart';
import axios from "axios";
import { Req_Status_URL } from '../API/Api';
import { DataContext } from '../Layouts/Data-Context';


function Dashboard() {

    const {expand} = useContext(DataContext);
    const [registrationStatus, setRegistrationStatus] = useState(false);
    const [image, setImage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        const user = localStorage.getItem("User");

        const input = {
            userName: user
        }

        axios.post(Req_Status_URL, input, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            let status = res.data.statusMessage;
            console.log(res);
            if(status == "Pending"){
                setRegistrationStatus(false);
                setImage('pending.gif');
                setStatusMessage(
                    <>
                      Your request is <Link to="/panel/theater-register" style={{color: "#FFC76C"}}>Pending</Link>. Please wait untill admin confirm your request.
                    </>
                );
            } else if(status == "Reject"){
                setRegistrationStatus(false);
                setImage('error.gif');
                setStatusMessage(
                    <>
                      Your request is <Link to="/panel/theater-register" style={{color: "#f00"}}>Rejected</Link>. Please  contact with admin.
                    </>
                );
            } else if(status == "Confirm"){
                setRegistrationStatus(false);
                setImage('success.gif');
                setStatusMessage(
                    <>
                      Your request has been <Link to="/panel/theater-register" style={{color: "rgb(63, 228, 79)"}}>Confirmed</Link> by admin.
                    </>
                );
            } else if(status == "Processing"){
                setRegistrationStatus(false);
                setImage('processing.gif');
                setStatusMessage(
                    <>
                      Your request is under <Link to="/panel/theater-register" style={{color: "rgb(63, 228, 79)"}}>Process</Link>.
                    </>
                );
            } else if(status == "Complete"){
                setRegistrationStatus(true);
            } else {
                setRegistrationStatus(false); 
                setImage('Thank-you.gif');
                setStatusMessage(
                    <>
                      Please <Link to="/panel/theater-register" style={{color: "#02C0FF"}}>Register</Link> your theater
                    </>
                );
            }
        })
        .catch(err => {
            setRegistrationStatus(false);
        })
    }, []);

    return(
        <>
            <DashboardWrapper>
                {
                    registrationStatus ? 
                    <div className="dashboard_inner">
                        <div className={`dashboard_box_sec ${expand ? 'expand' : ''}`}>
                            <Link to="/panel/screens" className="box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Screens</h4>
                                        <p>2</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-clapperboard"></i>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/panel/screens" className="box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Ticket Booked</h4>
                                        <p>255</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-ticket"></i>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/panel/all-movies" className="box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>This Week</h4>
                                        <p>155</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-film"></i>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/panel/today-showing" className="box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Today Showing</h4>
                                        <p>55</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-video"></i>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="superadmin_graph_sec">
                            <div className="user_graph">
                                <h4>Booking Data ( Weekly )</h4>
                                <div className="graph_box">
                                    <BookingData />
                                </div>
                            </div>
                            <div className="movie_graph">
                                <h4>Bookings ( Online & Offline )</h4>
                                <div className="graph_box">
                                    <TicketData />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="dashboard_message">
                        <img src={`${/images/}${image}`} alt="welcome" />
                        <h3>{statusMessage}</h3>
                    </div>
                }
            </DashboardWrapper>
        </>
    );

}


export default Dashboard;