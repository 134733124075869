import { styled } from "@mui/material";


export const ScreenModalBox = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .upload_box {
        position: relative;
        width: 600px;
        min-height: 150px;
        background: #FFF;
        box-shadow: 8px 12px 25px rgba(0,0,0,0.4);
        border-radius: 10px;
        padding: 40px;
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0px);
            transition: all 0.8s;
        }

        .box_inner {
            position: relative;
            width: 100%;

            form {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;

                .form_sec {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .select_box {
                        position: relative;
                        width: 47.5%;
                        margin-bottom: 25px;

                        input {
                            display: none;
                        }

                        .dropdown_btn {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            border: 2px solid #E1E0EA;
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 5px 25px;
                            font-family: 'Poppins', sans-serif;
                            cursor: pointer;
                            transition: 0.5s;

                            span {
                                position: absolute;
                                top: 50%;
                                left: 25px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                line-height: 1;
                                color: #555;
                                transform: translateY(-50%);
                                transition: 0.5s;
                            }

                            p {
                                position: relative;
                                max-width: calc(100% - 15px);
                                padding-right: 15px;
                                font-size: 16px;
                                line-height: 1.5;
                                color: #555;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            i {
                                font-size: 15px;
                                transition: 0.7s;

                                &.active {
                                    transform: rotate(-180deg);
                                    transition: 0.5s;
                                }
                            }
                        }

                        input:focus ~ .dropdown_btn,
                        input:valid ~ .dropdown_btn {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }

                        input:focus ~ .dropdown_btn span,
                        input:valid ~ .dropdown_btn span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }

                        .dropdown {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            z-index: 15;
                            background: #FFF;
                            border-radius: 5px;
                            box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.8s;

                            &.active {
                                max-height: 200px;
                                transition: all 0.8s;
                            }

                            ul {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                padding: 10px 10px;
                                max-height: 350px;
                                overflow-y: auto;
                                scrollbar-width: none;

                                li {
                                    position: relative;
                                    list-style: none;
                                    margin: 2px 0;
                                    padding: 13px 15px;
                                    color: #555;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 14px;
                                    line-height: 1;
                                    border-radius: 4px;
                                    transition: all 0.5s;
                                    cursor: pointer;

                                    &:hover {
                                        background: #FC6736;
                                        color: #FFF;
                                        transition: all 0.5s;
                                    }
                                }
                            }
                        }
                    }

                    .input_box {
                        position: relative;
                        width: 47.5%;
                        margin-bottom: 25px;

                        input {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            border: 2px solid #E1E0EA;
                            border-radius: 6px;
                            font-size: 16px;
                            line-height: 1;
                            padding: 5px 25px;
                            outline: none;
                            transition: 0.5s;
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                            pointer-events: none;
                        }

                        input:focus,
                        input:valid  {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }

                        input:focus ~ span,
                        input:valid ~ span{
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }
                    }

                    .upload_input_box {
                        position: relative;
                        width: 60%;

                        input[type="file"] {
                            display: none;
                        }

                        input[type="text"] {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            border: 2px solid #E1E0EA;
                            border-radius: 6px;
                            font-size: 16px;
                            line-height: 1;
                            padding: 5px 25px;
                            outline: none;
                            transition: 0.5s;
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                            pointer-events: none;
                        }

                        input[type="text"]:focus,
                        input[type="text"]:valid  {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }

                        input[type="text"]:focus ~ span,
                        input[type="text"]:valid ~ span{
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }
                    }

                    .upload_select_box {
                        position: relative;
                        width: 35%;

                        a {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            border: 2px solid #E5322D;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #E5322D;
                            border-radius: 6px;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0.66px;
                            color: #FFF;
                            cursor: pointer;
                        }
                    }
                }

                .form_btns {
                    position: relative;
                    width: 100%;
                    margin-top: 50px;
                    display: flex;
                    align-items: center;

                    button {
                        position: relative;
                        width: 180px;
                        height: 55px;
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFF;
                        overflow: hidden;
                        background: none;
                        border-radius: 6px;
                        border: 2px solid #3FE44F;
                        margin-right: 25px;

                        span {
                            position: relative;
                            z-index: 5;
                            color: #FFF;
                            transition: all 0.5s;

                            i {
                                font-size: 18px;
                                margin-right: 10px;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #3FE44F;
                            transform: scaleX(1);
                            transform-origin: left;
                            transition: transform 0.5s;
                        }

                        &:hover:before {
                            transform: scaleX(0);
                            transform-origin: right;
                            transition: transform 0.5s;
                        }

                        &:hover span {
                            color: #3FE44F;
                            transition: all 0.5s;
                        }
                    }

                    a {
                        position: relative;
                        width: 180px;
                        height: 55px;
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFF;
                        overflow: hidden;
                        background: none;
                        border-radius: 6px;
                        border: 2px solid #000;
                        margin-right: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            position: relative;
                            z-index: 5;
                            color: #FFF;
                            transition: all 0.5s;

                            i {
                                font-size: 18px;
                                margin-right: 10px;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #000;
                            transform: scaleX(1);
                            transform-origin: left;
                            transition: transform 0.5s;
                        }

                        &:hover:before {
                            transform: scaleX(0);
                            transform-origin: right;
                            transition: transform 0.5s;
                        }

                        &:hover span {
                            color: #000;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
`;

export const PriceModalBox = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .modal_box {
        position: relative;
        width: 600px;
        min-height: 150px;
        background: #FFF;
        box-shadow: 8px 12px 25px rgba(0,0,0,0.4);
        border-radius: 10px;
        padding: 40px;
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0px);
            transition: all 0.8s;
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .icon {
                position: relative;

                img {
                    position: relative;
                    width: 150px;
                }
            }

            .message {
                position: relative;

                p {
                    font-size: 16px;
                    color: #111;

                    span {
                        margin: 0px 6px;
                        font-size: 17px;
                        font-family: 'Lemonada', cursive;
                        font-weight: 600;
                        color: #3FE44F;
                    }
                }
            }

            .button {
                position: relative;
                margin-top: 50px;

                a {
                    position: relative;
                    width: 180px;
                    height: 55px;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: 600;
                    overflow: hidden;
                    background: none;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #FC6736;

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;
                        text-transform: uppercase;

                        i {
                            font-size: 18px;
                            transform: rotate(-45deg);
                            margin-right: 15px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #FC6736;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    &:hover span {
                        color: #FC6736;
                        transition: all 0.4s;
                    }
                }
            }
        }
    }
`;

export const ConfirmModalBox = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .modal_box {
        position: relative;
        width: 400px;
        min-height: 150px;
        background: #FFF;
        box-shadow: 8px 12px 25px rgba(0,0,0,0.4);
        border-radius: 10px;
        padding: 40px;
        transform: scale(0);
        transition: all 0.8s;

        &.active {
            transform: scale(1);
            transition: all 0.8s;
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .icon {
                position: relative;

                img {
                    position: relative;
                    width: 170px;
                }
            }

            .message {
                position: relative;
                margin-top: 5px;

                p {
                    font-size: 16px;
                    color: #111;
                    font-weight: 500;
                }
            }

            .button {
                position: relative;
                margin-top: 40px;

                a {
                    position: relative;
                    width: 160px;
                    height: 55px;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: 600;
                    overflow: hidden;
                    background: none;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #02C0FF;

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;
                        text-transform: uppercase;

                        i {
                            font-size: 18px;
                            transform: rotate(-45deg);
                            margin-right: 15px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #02C0FF;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    &:hover span {
                        color: #02C0FF;
                        transition: all 0.4s;
                    }
                }
            }
        }
    }
`;

export const WarningModalBox = styled('div')`
    position: fixed;
    top: 0;
    left: 300px;
    width: calc(100% - 300px);
    height: 100vh;
    z-index: 999;
    backdrop-filter: blur(7px);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal_box {
        position: relative;
        width: 550px;
        background: #FFF;
        box-shadow: 8px 12px 25px rgba(0,0,0,0.4);
        border-radius: 10px;
        padding: 40px;

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .icon {
                position: relative;

                img {
                    position: relative;
                    width: 150px;
                }
            }
            .message {
                position: relative;
                margin-top: 25px;
                width: 100%;

                p {
                    position: relative;
                    font-size: 16px;
                    line-height: 1.5;
                    color: #F00;
                }
            }
        }
    }
`;