import { useEffect, useState } from "react";
import { SidebarWrapper } from "../Styles/Navigations-Style";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import { Req_Status_URL } from "../API/Api";


function Sidebar({setPageName, sidebarReload, shrink, sidebarExpand, setSidebarExpand}) {

    const location = useLocation();

    const [screenDropdown, setScreenDropdown] = useState(false);
    const [reportDropdown, setReportDropdown] = useState(false);
    const [movieDropdown, setMovieDropdown] = useState(false);
    const [theaterRegistration, setTheaterRegistration] = useState(false);
    const [username, setUsername] = useState('');

    useEffect(() => {
        const formatPathName = (pathName) => {
            return pathName.split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };

        const pathName = location.pathname.split('/').filter(Boolean).pop() || 'Dashboard';
        const formattedPageName = formatPathName(pathName);

         if(pathName == "all-movies" || pathName == "today-showing" || pathName == "request-movie" || pathName == "add-movies" || pathName == "reviews"){
            setMovieDropdown(true);
        } else {
            setMovieDropdown(false);
        }

        if(pathName == "screens" || pathName == "seat-booking" || pathName == "seat-block" || pathName == "screen-settings"){
            setScreenDropdown(true);
        } else {
            setScreenDropdown(false);
        }

        if(pathName == "transaction-report" || pathName == "booking-report"){
            setReportDropdown(true);
        } else {
            setReportDropdown(false);
        }

        setPageName(formattedPageName);
    },[location]);

    useEffect(() => {
        const user = localStorage.getItem("User");
        setUsername(user);

        const input = {
            userName: user
        }

        axios.post(Req_Status_URL, input, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            let status = res.data.statusMessage;
            if(status == "Pending"){
                setTheaterRegistration(true);
            } else if(status == "Reject"){
                setTheaterRegistration(true);
            } else if(status == "Confirm"){
                setTheaterRegistration(true);
            } else if(status == "Processing"){
                setTheaterRegistration(true);
            } else if(status == "Complete"){
                setTheaterRegistration(true);
            }
        })
        .catch(err => {
            setTheaterRegistration(false);
        })
    }, [sidebarReload]);

    function handleScreenDropdown() {
        setScreenDropdown(!screenDropdown);
        setReportDropdown(false);
        setMovieDropdown(false);
    }

    function handleReportDropdown() {
        setReportDropdown(!reportDropdown);
        setScreenDropdown(false);
        setMovieDropdown(false);
    }

    function handleMovieDropdown() {
        setMovieDropdown(!movieDropdown);
        setReportDropdown(false);
        setScreenDropdown(false);
    }


    return(
        <>
            <SidebarWrapper>
                <div className={`sidebar_logo ${shrink ? 'shrink': ''}`}>
                    <img src="/images/Logo.png" alt="logo" />
                    <h3>Ticket <span>Bay</span></h3>
                </div>
                <div className={`sidebar_items ${shrink ? 'shrink': ''}`}>
                    <div className="items_inner_sec">
                        <NavLink to="/panel/dashboard">
                            <span></span>
                            <span></span>
                            <p><i className="fa-solid fa-gauge"></i>{!shrink && <>Dashboard</>}</p>
                        </NavLink>
                        <NavLink to="/panel/theater-register">
                            <span></span>
                            <span></span>
                            <p><i className="fa-solid fa-building-user"></i>{!shrink && <>{theaterRegistration ? 'Registration Status' : 'Theater Register'}</>}</p>
                        </NavLink>
                        {
                            theaterRegistration && 
                            <div className="dropdown_item">
                                <div className="dropdown_btn">
                                    <li className={screenDropdown? 'active':''} onClick={handleScreenDropdown}><i className="fa-solid fa-clapperboard icon"></i>{!shrink && <>Screens <i className={`fa-solid fa-chevron-right arrow ${screenDropdown? 'rotate':''}`}></i></>}</li>
                                </div>
                                <div className={`dropdown ${screenDropdown? 'active':''}`}>
                                    <NavLink to="/panel/screens">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-circle-info"></i>{!shrink && <>Screen Details</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/seat-booking">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-couch"></i>{!shrink && <>Seat Booking</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/seat-block">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-ban"></i>{!shrink && <>Seat Block</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/screen-settings">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-gears"></i>{!shrink && <>Screen Settings</>}</p>
                                    </NavLink>
                                </div>
                            </div>
                        }
                        {
                            theaterRegistration && 
                            <NavLink to="/panel/settlements">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-file-pen"></i>{!shrink && <>Settlemets</>}</p>
                            </NavLink>
                        }
                        {
                            theaterRegistration &&
                            <div className="dropdown_item">
                                <div className="dropdown_btn">
                                    <li className={reportDropdown? 'active':''} onClick={handleReportDropdown}><i className="fa-solid fa-file-lines icon"></i>{!shrink && <>Reports<i className={`fa-solid fa-chevron-right arrow ${reportDropdown? 'rotate':''}`}></i></>}</li>
                                </div>
                                <div className={`dropdown ${reportDropdown? 'active':''}`}>
                                    <NavLink to="/panel/booking-report">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-ticket"></i>{!shrink && <>Booking Report</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/transaction-report">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-credit-card"></i>{!shrink && <>Transaction Report</>}</p>
                                    </NavLink>
                                </div>
                            </div>
                        }
                        {
                            theaterRegistration &&
                            <div className="dropdown_item">
                                <div className="dropdown_btn">
                                    <li className={movieDropdown? 'active':''} onClick={handleMovieDropdown}><i className="fa-solid fa-film icon"></i>{!shrink && <>Movies <i className={`fa-solid fa-chevron-right arrow ${movieDropdown? 'rotate':''}`}></i></>}</li>
                                </div>
                                <div className={`dropdown ${movieDropdown? 'active':''}`}>
                                    <NavLink to="/panel/add-movies">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-plus"></i>{!shrink && <>Select Movie</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/all-movies">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-video"></i>{!shrink && <>All Movies</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/today-showing">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-compact-disc"></i>{!shrink && <>Now Showing</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/request-movie">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-code-pull-request"></i>{!shrink && <>Request Movie</>}</p>
                                    </NavLink>
                                    <NavLink to="/panel/reviews">
                                        <span></span>
                                        <span></span>
                                        <p><i className="fa-solid fa-star-half-stroke"></i>{!shrink && <>Reviews</>}</p>
                                    </NavLink>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="res_profile_sec">
                        <img src="/images/profile.png" alt="profile" />
                        <p>{username}</p>
                        <a><i className="fa-solid fa-right-from-bracket"></i></a>
                    </div>
                </div>
            </SidebarWrapper>
        </>
    );
}


export default Sidebar;