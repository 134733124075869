import { useEffect, useRef, useState } from "react";
import { ScreenModalBox } from "../Styles/Modal-Styles";
import axios from "axios";
import { Screen_Upload_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ScreenDetailModal({showModal, setShowModal, screenInputs, userName}) {

    const [screens, setScreens] = useState(0);
    const [screen, setScreen] = useState('');
    const [fileName, setFileName] = useState('');
    const [screenDropdown, setScreenDropdown] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    const screenRef = useRef();
    const capacityRef = useRef();


    useEffect(() => {
        setScreens(Number(screenInputs.screenNo) || 0);
        // console.log(screenInputs);
    },[screenInputs]);

    const handleScreenDropdown = () => {
        setScreenDropdown(!screenDropdown);
    };

    const closeModal = () => {
        setShowModal(false);
        clearForm();
    };

    const handleFileSelect = () => {
        document.getElementById('file_input').click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setSelectedFile(file);
    };

    const clearForm = () => {
        setScreen('');
        setFileName('');
        setSelectedFile(null);
        if (screenRef.current) screenRef.current.value = '';
        if (capacityRef.current) capacityRef.current.value = '';
    };

    function handleSubmit(e) {
        e.preventDefault();

        const screenData = new FormData();
        screenData.append('userName', userName);
        screenData.append('image', selectedFile);
        screenData.append('theater', screenInputs.name);
        screenData.append('screen', screenRef.current.value);
        screenData.append('capacity', capacityRef.current.value);

        axios.post(Screen_Upload_URL, screenData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            let status = res.data.status;

            if(status == "200"){
                toast.success(`${screenRef.current.value} details uploaded !!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                clearForm();
            }
        })
        .catch(err => {
            let status = err.response.data.status;
            // console.log(status);

            if(status == "404"){
                toast.warn('This Screen details already exists !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "400"){
                toast.warn('Please upload an image file !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "403"){
                toast.error('Internal Server Error !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "500"){
                toast.error('Uploading Error !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "505"){
                toast.error('Invalid Request !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
    }

    return(
        <>
            <ScreenModalBox className={showModal ? 'active' : ''}>
                <div className={`upload_box ${showModal ? 'active' : ''}`}>
                    <div className="box_inner">
                        <form onSubmit={handleSubmit}>
                            <div className="form_sec">
                                <div className="select_box">
                                    <input type="text" id="screen" ref={screenRef} required />
                                    <div className="dropdown_btn" onClick={handleScreenDropdown}>
                                        <p>{screen}</p>
                                        <span>Select Screen</span>
                                        <i className={`fa-solid fa-angle-down ${screenDropdown? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${screenDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                Array.from({ length: screens }, (_, index) => (
                                                    <li key={index} onClick={() => {
                                                        setScreen(`Screen ${index + 1}`);
                                                        setScreenDropdown(false);
                                                        document.getElementById('screen').value = `Screen ${index + 1}`;
                                                    }}>
                                                        {`Screen ${index + 1}`}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="input_box">
                                    <input type="text" ref={capacityRef} required />
                                    <span>Seat Capacity</span>
                                </div>
                                <div className="upload_input_box">
                                    <input type="file" name="file" id="file_input" accept="image/jpg, image/jpeg, image/png" onChange={handleFileChange} required />
                                    <input type="text" value={fileName} required />
                                    <span>File Name</span>
                                </div>
                                <div className="upload_select_box">
                                    <a onClick={handleFileSelect}>Browse</a>
                                </div>
                            </div>
                            <div className="form_btns">
                                <button type="submit"><span><i className="fa-solid fa-cloud-arrow-up"></i>Upload</span></button>
                                <a onClick={closeModal}><span><i className="fa-solid fa-xmark"></i>Close</span></a>
                            </div>
                        </form>
                    </div>
                </div>
            </ScreenModalBox>
        </>
    );
}


export default ScreenDetailModal;