import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import { PanelLayout } from "../Styles/Layout-Style";
import { useEffect, useState } from "react";
import { DataContext } from "./Data-Context";


function PaneLayout() {

    const navigate = useNavigate();
    const [sidebarReload, setSidebarReload] = useState(false);
    const [sidebarExpand, setSidebarExpand] = useState(false);

    const getShrinkExpandState = () => {
        if (window.innerWidth <= 768) return false;
        return window.innerWidth <= 1299;
    };

    const [shrink, setShrink] = useState(getShrinkExpandState());
    const [expand, setExpand] = useState(getShrinkExpandState());
    
    useEffect(() => {
        
        let loginStatus = localStorage.getItem("LoggedIn");
        
        if(!loginStatus || loginStatus !== "true") {
            navigate('/');
        } 

        const handleResize = () => {
            const state = getShrinkExpandState();
            setShrink(state);
            setExpand(state);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [pageName, setPageName] = useState('Dashboard');
    
    function closeSidebar() {
        setSidebarExpand(false);
    }

    return(
        <>
            <PanelLayout>
                <div className={`left_side ${shrink ? 'shrink': ''} ${sidebarExpand ? 'active': ''}`}>
                    <div className="res_sidebar_close">
                        <a onClick={closeSidebar}><i className="fa-solid fa-chevron-left"></i></a>
                    </div>
                    <Sidebar setPageName={setPageName} sidebarReload={sidebarReload} shrink={shrink} sidebarExpand={sidebarExpand} setSidebarExpand={setSidebarExpand} />
                </div>
                <div className={`right_side ${expand ? 'expand' : ''}`}>
                    <div className="top_bar">
                        <Navbar pageName={pageName} shrink={shrink} setShrink={setShrink} expand={expand} setExpand={setExpand} sidebarExpand={sidebarExpand} setSidebarExpand={setSidebarExpand} />
                    </div>
                    <div className="main_content">
                        <div className="content_inner">
                            <div className="inner_items">
                                <DataContext.Provider value={{expand, setSidebarReload}}>
                                    <Outlet />
                                </DataContext.Provider>
                            </div>
                        </div>
                    </div>
                </div>
            </PanelLayout>
        </>
    );
}


export default PaneLayout;