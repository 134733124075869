import { useEffect, useState } from "react";
import { SeatLayoutWrapper } from "../Styles/Layout-Style";
import ModalPrice from "../Modals/PriceModal";
import ModalConfirm from "../Modals/ConfirmModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from 'swiper/modules';
import axios from "axios";
import { Block_Seats_URL, Book_Seats_URL, Fetch_Seats_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';

import 'swiper/css';
import 'swiper/css/free-mode';




function SeatLayout({booking, theater, bookedSeats, selectedScreen, selectedMovie, refreshBookedSeats, sectionWiseBlock, selectedSection, permittedSeatsAmount}) {

    const [showSeatLayout, setShowSeatLayout] = useState(false);
    const [seatsData, setSeatsData] = useState([]);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [totalPrice, setTotalPrice] = useState('');
    const [priceModalShow, setPriceModalShow] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);


    useEffect(() => {
        if(selectedMovie.movie_name) {
            setShowSeatLayout(true);
        } else {
            setShowSeatLayout(false);
        }
    }, [selectedMovie]);

    useEffect(() => {
        let url = `${Fetch_Seats_URL}?id=${selectedScreen.screen_id}`;
        if(sectionWiseBlock) {
            url += `&section=${selectedSection.section}&sectionName=${selectedSection.sec_name}`;
        }
        axios.get(url)
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200"){
                setSeatsData(res.data.seatData);
                setSelectedSeats([]);
            }
        })
        .catch(err => {
            console.log(err);
            setSeatsData([]);
        })
    }, [selectedScreen, selectedSection]);


    const indexToAlphabet = (index) => {
        let letters = '';
        while (index >= 0) {
            letters = String.fromCharCode((index % 26) + 65) + letters;
            index = Math.floor(index / 26) - 1;
        }
        return letters;
    };

    const parseSeatsString = (seatsString) => {
        return seatsString.split(',').map(seat => parseInt(seat.trim(), 10));
    };

    const parseBookedSeatsString = (bookedString) => {
        return bookedString ? bookedString.split(',').map(seat => seat.trim()) : [];
    };

    const getBookedSeatsForSection = (sectionName) => {
        if (!Array.isArray(bookedSeats)) return [];
        const section = bookedSeats.find(section => section.section === sectionName);
        return section ? parseBookedSeatsString(section.total_bookings) : [];
    };

    const getOnlineBookedSeatsForSection = (sectionName) => {
        if (!Array.isArray(bookedSeats)) return [];
        const section = bookedSeats.find(section => section.section === sectionName);
        return section ? parseBookedSeatsString(section.online_bookings) : [];
    };

    const getOfflineBookedSeatsForSection = (sectionName) => {
        if (!Array.isArray(bookedSeats)) return [];
        const section = bookedSeats.find(section => section.section === sectionName);
        return section ? parseBookedSeatsString(section.offline_bookings) : [];
    };

    const getBlockedSeatsForSection = (sectionName) => {
        if (!Array.isArray(bookedSeats)) return [];
        const section = bookedSeats.find(section => section.section === sectionName);
        return section ? parseBookedSeatsString(section.block_seats) : [];
    };

    const handleSeatClick = (sectionName, row, seatNumber, price) => {
        const seatIdentifier = `${sectionName}-${seatNumber}`;
        setSelectedSeats(prevSelectedSeats => {
            if (!booking) {
                if (prevSelectedSeats.length >= Number(permittedSeatsAmount)) {
                    toast.warn(`You can't select more than permitted amount of seats.`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    return prevSelectedSeats;
                }
            }
            if (prevSelectedSeats.some(seat => seat.identifier === seatIdentifier)) {
                return prevSelectedSeats.filter(seat => seat.identifier !== seatIdentifier);
            } else {
                return [...prevSelectedSeats, { sectionName, row, seatNumber, price: Number(price), identifier: seatIdentifier }];
            }
        });
    };


    const handleBooking = (e) => {
        e.preventDefault();

        const totalValue = selectedSeats.reduce((acc, seat) => acc + seat.price, 0);

        const groupedSeats = selectedSeats.reduce((acc, seat) => {
            if (!acc[seat.sectionName]) {
                acc[seat.sectionName] = {};
            }
            if (!acc[seat.sectionName][seat.row]) {
                acc[seat.sectionName][seat.row] = [];
            }
            acc[seat.sectionName][seat.row].push(seat);
            return acc;
        }, {});
    
        // Transform the groupedSeats into the desired result format
        const result = Object.entries(groupedSeats).flatMap(([sectionName, rows]) => {
            return Object.entries(rows).map(([row, seats]) => {
                const seatIdentifiers = seats.map(seat => `${seat.seatNumber}`);
                const seatNumbers = seats.map(seat => seat.seatNumber.replace(`${row}-`, '')).sort((a, b) => a - b);;
                return {
                    theater: theater,
                    screen: selectedScreen.screen,
                    screen_id: selectedScreen.screen_id,
                    movie_name: selectedMovie.movie_name,
                    sectionName: sectionName,
                    row: row,
                    date: selectedMovie.date,
                    time: selectedMovie.time,
                    seats: seatIdentifiers.join(', '),
                    seatNumbers: seatNumbers.join(', '),
                    seatCount: seats.length,
                    price: totalValue
                };
            });
        });

        setTotalPrice(totalValue);

        if(selectedSeats.length > 0) {
            axios.post(Book_Seats_URL, result, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                setSelectedSeats([]);
                setPriceModalShow(true);
                printReceipt(result[0], totalValue);
            })
            .catch(err => {
                console.log(err);
            })
        } else {
            toast.warn(`You haven't selected any seats.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }

        // console.log(selectedSeats.length);
    };

    const handleBlock = (e) => {
        e.preventDefault();

        const groupedSeats = selectedSeats.reduce((acc, seat) => {
            if (!acc[seat.sectionName]) {
                acc[seat.sectionName] = {};
            }
            if (!acc[seat.sectionName][seat.row]) {
                acc[seat.sectionName][seat.row] = [];
            }
            acc[seat.sectionName][seat.row].push(seat);
            return acc;
        }, {});
    
        // Transform the groupedSeats into the desired result format
        const result = Object.entries(groupedSeats).flatMap(([sectionName, rows]) => {
            return Object.entries(rows).map(([row, seats]) => {
                const seatIdentifiers = seats.map(seat => `${seat.seatNumber}`);
                const seatNumbers = seats.map(seat => seat.seatNumber.replace(`${row}-`, '')).sort((a, b) => a - b);;
                return {
                    theater: theater,
                    screen: selectedScreen.screen,
                    screen_id: selectedScreen.screen_id,
                    movie_name: selectedMovie.movie_name,
                    sectionName: sectionName,
                    row: row,
                    date: selectedMovie.date,
                    time: selectedMovie.time,
                    seats: seatIdentifiers.join(', '),
                    seatNumbers: seatNumbers.join(', '),
                    seatCount: seats.length
                };
            });
        });

        if(selectedSeats.length > 0) {
            axios.post(Block_Seats_URL, result, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                toast.success(`You haven blocked ${selectedSeats.length} seats.`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setSelectedSeats([]);
                refreshBookedSeats();
            })
            .catch(err => {
                console.log(err);
            })
        } else {
            toast.warn(`You haven't selected any seats.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }

        // console.log(selectedSeats.length);
    };

    const printReceipt = (result, totalValue) => {
        const receiptContent = `
            <html>
            <head>
                <style>
                    @media print {
                        body {
                            margin: 0;
                            padding: 0;
                            width: 4in; /* Set width to 4 inches */
                            height: auto; /* Allow height to adjust to content */
                        }
                        .container{
                            width: 100%;
                            max-width: 90mm;
                            margin: 0 auto;
                            padding: 10px;
                            box-sizing: border-box;
                        }
                        .container .sec{
                            text-align: center;
                        }
                        .container .sec img{
                            width: 35px;
                        }
                        .container .box{
                            margin-top: 20px;
                        }
                        .container .box.seats{
                            margin-top: 10px;
                        }
                    
                        .container .box .name{
                            text-align: center;
                            margin-bottom: 15px;
                            font-size: 18px;
                        }
                        .container .box p{
                            margin-bottom: 8px;
                            font-size: 17px;
                            font-weight: 500;
                            color: #555;
                        }
                        .container .box p span{
                            color: #000;
                            font-weight: 600;
                        }
                        .receipt-table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-top: 15px;
                        }
                        .receipt-table th{
                            border: 1px solid #ccc;
                            padding: 5px;
                            text-align: left;
                            font-size: 17px;
                            color: #000;
                            font-weight: 600;
                            padding: 5px 15px;
                        }
                        .receipt-table td {
                            border: 1px solid #ccc;
                            padding: 5px;
                            text-align: left;
                            font-size: 17px;
                            color: #555;
                            font-weight: 500;
                            padding: 5px 15px;
                        }
                    
                        .container .receipt-total{
                            margin-top: 20px;
                            font-size: 17px;
                        }
                        .container .footer{
                            text-align: center;
                            margin-top: 45px;
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }
                </style>
            </head>
           <body>
                <div class="container">
                    <div class="sec"><img src="/images/Logo.png" alt=""></div>
                    <div class="box">
                        <h5 class="name">${result.theater}</h5>
                        <p><span>Show: </span> ${result.movie_name}</p>
                        <p><span>Date: </span> ${result.date}</p>
                        <p><span>Time: </span> ${result.time}</p>
                    </div>
                    <table class="receipt-table">
                        <thead>
                            <tr>
                                <th>Section</th>
                                <th>Row</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>${result.sectionName}</td>
                            <td>${result.row}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="box seats">
                        <p><span>Seat No.: </span> ${result.seatNumbers}</p>
                    </div>
                    <div class="receipt-total">
                        <p><strong>Total Price:</strong> ${totalValue}</p>
                    </div>
                    <div class="footer">
                        <p>Thank You</p>
                    </div>
                </div>
            </body>
            </html>
        `;
  
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);
  
        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(receiptContent);
        doc.close();
  
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
  
        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 1000);
    };


    return(
        <>
            <SeatLayoutWrapper>
                <div className="layout_inner">
                    <div className="inner_content">
                        <div className="seats_layout">
                            <div className="scroll_sec">
                                <Swiper
                                    freeMode={true}
                                    slidesPerView={'auto'}
                                    modules={[FreeMode]}
                                    className="mySwiper"
                                    style={{ overflow: 'visible' }}
                                >
                                    <SwiperSlide>
                                        {
                                            showSeatLayout && 
                                            <div className="screen_box">
                                                <div className="display_image">
                                                    <img src="/images/Screen-Display.svg" alt="Display" />
                                                </div>
                                                <div className="seat_sections">
                                                    {
                                                        seatsData && seatsData.map((seatData, index) => {
                                                            const bookedSeatsForSection = getBookedSeatsForSection(seatData.sec_name);
                                                            const onlineBookedSeatsForSection = getOnlineBookedSeatsForSection(seatData.sec_name);
                                                            const offlineBookedSeatsForSection = getOfflineBookedSeatsForSection(seatData.sec_name);
                                                            const blockedSeatsForSection = getBlockedSeatsForSection(seatData.sec_name);
                                                            // console.log(`Section: ${seatData.sec_name}`, 'Online Booked Seats:', onlineBookedSeatsForSection);
                                                            // console.log(`Section: ${seatData.sec_name}`, 'Offline Booked Seats:', offlineBookedSeatsForSection);
                                                            // console.log(`Section: ${seatData.sec_name}`, 'Blocked Seats:', blockedSeatsForSection);
                                                            return(
                                                                <div className="section" key={index}>
                                                                    <div className="sec_name">
                                                                        <h5>{seatData.sec_name}</h5>
                                                                        <p>( Rs. {seatData.price}/- )</p>
                                                                    </div>
                                                                    <div className="sec_seat_rows">
                                                                        {
                                                                            (seatData.rows).map((seats, idx) => {
                                                                                const gapSeats = parseSeatsString(seats.gap_seats || '');
                                                                                const gapAmounts = parseSeatsString(seats.gap_amounts || '');
                                                                                return(
                                                                                    <div className="seat_row" key={idx}>
                                                                                        <div className="seats">
                                                                                            {
                                                                                                Array.from({ length: seats.seats }, (_, i) => {
                                                                                                    const isGap = gapSeats.includes(i + 1);
                                                                                                    const gapIndex = gapSeats.indexOf(i + 1);
                                                                                                    const marginLeft = isGap && gapIndex !== -1 ? `${gapAmounts[gapIndex] * 40}px` : '0';
                                                                                                    const seatNumber = `${indexToAlphabet(idx)}-${i + 1}`;
                                                                                                    const isBooked = bookedSeatsForSection.includes(seatNumber);
                                                                                                    const onlineBooked = onlineBookedSeatsForSection.includes(seatNumber);
                                                                                                    const offlineBooked = offlineBookedSeatsForSection.includes(seatNumber);
                                                                                                    const blocked = blockedSeatsForSection.includes(seatNumber);
                                                                                                    
                                                                                                    return(
                                                                                                        <li key={i} 
                                                                                                            className={`
                                                                                                            ${selectedSeats.some(seat => seat.identifier === `${seatData.sec_name}-${indexToAlphabet(idx)}-${i + 1}`) ? `${booking ? 'selected' : 'block_selected'}` : ''} 
                                                                                                            ${isBooked ? 'booked' : ''} ${onlineBooked ? 'online_booked' : ''} ${offlineBooked ? 'offline_booked' : ''} ${blocked ? 'blocked' : ''}`} 
                                                                                                            onClick={() => handleSeatClick(seatData.sec_name, `${indexToAlphabet(idx)}`, `${indexToAlphabet(idx)}-${i + 1}`, seatData.price)}
                                                                                                            style={i === 0 ? { marginRight: `${seats.starting * 40}px`, marginLeft } : { marginLeft }}>
                                                                                                            <i className="fa-solid fa-couch"></i>
                                                                                                            <p>{i + 1}</p>
                                                                                                        </li>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        <span className="index">{indexToAlphabet(idx)}</span>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                        {
                            showSeatLayout && 
                            <div className="button_sec">
                                <div className="indications">
                                    <li className="online">
                                        <i className="fa-solid fa-couch"></i>
                                        <p>Online Booking</p>
                                    </li>
                                    <li className="offline">
                                        <i className="fa-solid fa-couch"></i>
                                        <p>Offline Booking</p>
                                    </li>
                                    <li className="block">
                                        <i className="fa-solid fa-couch"></i>
                                        <p>Blocked</p>
                                    </li>
                                </div>
                                {
                                    booking ? 
                                    <a className="booking_btn" onClick={handleBooking}><span><i className="fa-solid fa-ticket"></i>Book Now</span></a>
                                    :
                                    <a className="block_btn" onClick={handleBlock}><span><i className="fa-solid fa-ticket"></i>Block Seats</span></a>
                                }
                            </div>
                        }
                    </div>
                </div>

                <ModalPrice priceModalShow={priceModalShow} setPriceModalShow={setPriceModalShow} setConfirmModalShow={setConfirmModalShow} totalPrice={totalPrice} />
                <ModalConfirm confirmModalShow={confirmModalShow} setConfirmModalShow={setConfirmModalShow} refreshBookedSeats={refreshBookedSeats} />
            </SeatLayoutWrapper>
        </>
    );
}


export default SeatLayout;