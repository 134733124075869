import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ScreenLayoutWrapper } from "../Styles/Layout-Style";
import { Block_Permission_Check_URL, Fetch_Booked_Seats_URL, Fetch_Screens_URL, Upcoming_Screen_Shows_URL } from "../API/Api";
import SeatLayout from "../Components/SeatLayout";
import WarningModal from "../Modals/WarningModal";


function SeatBlock() {

    const [noRegeisterShow, setNoRegeisterShow] = useState(true);
    const [RegeisteredShow, setRegeisteredShow] = useState(false);
    const [screenDropdown, setScreenDropdown] = useState(false);
    const [moviesDropdown, setMoviesDropdown] = useState(false);
    const [screensShow, setScreensShow] = useState(false);
    const [theater, setTheater] = useState('');
    const [screens, setScreens] = useState([]);
    const [movies, setMovies] = useState([]);
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [reload, setReload] = useState(false);
    const [checkPublish, setCheckPublish] = useState(false);
    const [selectedMovieName, setSelectedMovieName] = useState('');
    const [selectedMovie, setSelectedMovie] = useState([]);
    const [tempSelectedMovie, setTempSelectedMovie] = useState([]);
    const [bookedSeats, setBookedSeats] = useState([]);
    const [blockPermission, setBlockPermission] = useState(false);
    const [sectionWiseBlock, setSectionWiseBlock] = useState(false);
    const [sectionsDropdown, setSectionsDropdown] = useState(false);
    const [screenSections, setScreenSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState('');
    const [permittedSeatsAmount, setPermittedSeatsAmount] = useState('');

    useEffect(() => {
        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Screens_URL}?user=${user}`)
            .then(res => {
                let status = res.data.status;

                if (status == "202") {
                    setNoRegeisterShow(true);
                    setRegeisteredShow(false);
                } else if (status == "200") {
                    setNoRegeisterShow(false);
                    setRegeisteredShow(false);
                    setScreensShow(true);
                    setTheater(res.data.theater);
                    setSelectedScreen(res.data.screens[0]);
                    setScreens(res.data.screens);
                    setReload(false);
                }
            })
            .catch(err => {
                // console.log(err);
                setNoRegeisterShow(false);
                setRegeisteredShow(true);
            });
    }, [reload]);

    useEffect(() => {
        console.log(selectedScreen);
        if (selectedScreen && selectedScreen.prices == "not-set") {
            setCheckPublish(false);
        } else {
            setCheckPublish(true);
        }
    }, [selectedScreen]);

    useEffect(() => {
        if(selectedScreen) {
            axios.get(`${Block_Permission_Check_URL}?screen=${selectedScreen.screen}&&screen_id=${selectedScreen.screen_id}`)
            .then(res => {
                console.log("wurhjnt5swhrek", res);
                if(res.data.status == "202") {
                    setBlockPermission(true);
                    setScreenSections([]);
                } else if(res.data.status == "200") {
                    setBlockPermission(false);
                    if(res.data.blockType === "Screen Wise") {
                        setSectionWiseBlock(false);
                        setPermittedSeatsAmount(res.data.seat_amount);
                        setScreenSections([]);
                    } else if(res.data.blockType === "Section Wise") {
                        setSectionWiseBlock(true);
                        setScreenSections(res.data.sections);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [selectedScreen]);

    useEffect(() => {
        if (selectedScreen) {
            axios.get(`${Upcoming_Screen_Shows_URL}?id=${selectedScreen.screen_id}`)
                .then(res => {
                    // console.log(res.data);
                    setMovies(res.data.movies);
                    setSelectedMovie([]);
                    setSelectedMovieName('');
                    setBookedSeats([]);
                })
                .catch(err => {
                    // console.log(err);
                    setMovies();
                    setBookedSeats([]);
                });
        }
    }, [selectedScreen]);

    const handleScreenDropdown = () => {
        setScreenDropdown(!screenDropdown);
        setMoviesDropdown(false);
        setSectionsDropdown(false);
    };

    const handleMoviesDropdown = () => {
        setScreenDropdown(false);
        setMoviesDropdown(!moviesDropdown);
        setSectionsDropdown(false);
    };

    const handleSectionsDropdown = () => {
        setScreenDropdown(false);
        setMoviesDropdown(false);
        setSectionsDropdown(!sectionsDropdown);
    };

    const handleBookedSeatsScreenWise = (movieName, date, time) => {

        const inputs = {
            theater: theater,
            screen: selectedScreen.screen,
            screen_id: selectedScreen.screen_id,
            movie_name: movieName,
            date: date,
            time: time
        };

        axios.post(Fetch_Booked_Seats_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log("wuegfirbgeriugy8rubgnjetkgn", res);
            setBookedSeats(res.data.booked);
            if(!sectionWiseBlock){
                const blockedSeats = res.data.total_blocked_seats;
                const permittedSeats = Number(permittedSeatsAmount);
                const updatedPermittedSeatsAmount = permittedSeats - blockedSeats;
                setPermittedSeatsAmount(updatedPermittedSeatsAmount);
            }
        })
        .catch(err => {
            // console.log(err);
            setBookedSeats([]);
        })

    };

    const handleBookedSeatsSectionWise = (movieName, date, time, section, permittedAmount) => {

        const inputs = {
            theater: theater,
            screen: selectedScreen.screen,
            screen_id: selectedScreen.screen_id,
            movie_name: movieName,
            date: date,
            time: time,
            section: section
        };

        axios.post(Fetch_Booked_Seats_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            if(res.data.booked.length > 0) {
                // console.log("wuegfirbgeriugy8rubgnjetkgn", res);
                setBookedSeats(res.data.booked);
                const processedBookedSeats = res.data.booked.map(booking => {
                    return {
                        ...booking,
                        block_seats: booking.block_seats.split(', ') // Convert the comma-separated string to an array
                    };
                });
                const blockedSeats = processedBookedSeats[0].block_seats.length;
                const permittedSeats = Number(permittedAmount);
                const updatedPermittedSeatsAmount = permittedSeats - blockedSeats;
                setPermittedSeatsAmount(updatedPermittedSeatsAmount);
            }
        })
        .catch(err => {
            // console.log(err);
            setBookedSeats([]);
        })

    };

    const refreshBookedSeats = () => {
        if (selectedMovie.movie_name && selectedMovie.date && selectedMovie.time) {
            if(sectionWiseBlock) {
                handleBookedSeatsSectionWise(selectedMovie.movie_name, selectedMovie.date, selectedMovie.time, selectedSection.sec_name);
            } else {
                handleBookedSeatsScreenWise(selectedMovie.movie_name, selectedMovie.date, selectedMovie.time);
            }
        }
    };


    return(
        <>
            <ScreenLayoutWrapper>
                <div className="wrapper_inner">
                    {
                        noRegeisterShow &&
                        <div className="message">
                            <p>You haven't registered your theater yet. <Link to="/panel/theater-register">Register Now</Link></p>
                        </div>
                    }
                    {
                        RegeisteredShow &&
                        <div className="message">
                            <p>Your have already registered. Please wait for admin to respond. If you want to see your request status go to <Link to="/panel/theater-register">Theater Register</Link>.</p>
                        </div>
                    }

                    {
                        screensShow &&
                        <div className="screen_layout">
                            <div className={`inner_top ${sectionWiseBlock ? 'sectionwise_block' : ''}`}>
                                <div className="screen_select_sec">
                                    <div className="icon"><img src="/images/screen.png" alt="icon" /></div>
                                    <div className="dropdown_sec">
                                        <input type="text" value={selectedScreen ? selectedScreen.screen : ''} id="screen" required />
                                        <div className="dropdown_btn" onClick={handleScreenDropdown}>
                                            <p>{selectedScreen ? selectedScreen.screen : 'Select Screen'}</p>
                                            <span>Select Screen</span>
                                            <i className={`fa-solid fa-angle-down ${screenDropdown ? 'active' : ''}`}></i>
                                        </div>
                                        <div className={`dropdown ${screenDropdown ? 'active' : ''}`}>
                                            <ul>
                                                {
                                                    screens.map((screen, index) =>
                                                        <li key={index}
                                                            onClick={() => {
                                                                setSelectedScreen(screen);
                                                                setScreenDropdown(false);
                                                            }}
                                                        >{screen.screen}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="movie_details_sec">
                                    <div className="dropdown_sec">
                                        <input type="text" value={selectedMovieName} required />
                                        <div className="dropdown_btn" onClick={handleMoviesDropdown}>
                                            <p>{selectedMovieName}</p>
                                            <span>Select Movie</span>
                                            <i className={`fa-solid fa-angle-down ${moviesDropdown? 'active':''}`}></i>
                                        </div>
                                        <div className={`dropdown ${moviesDropdown? 'active':''}`}>
                                            <ul>
                                                {
                                                    movies && movies.map((movie, index) =>
                                                        <li key={index}
                                                            onClick={(e) => {
                                                                setSelectedMovieName(movie.movie_name)
                                                                if(!sectionWiseBlock){
                                                                    setSelectedMovie({movie_name: movie.movie_name, language: movie.language, date: movie.date, time: movie.time})
                                                                    handleBookedSeatsScreenWise(movie.movie_name, movie.date, movie.time)
                                                                } else {
                                                                    setTempSelectedMovie(movie)
                                                                }
                                                                setMoviesDropdown(false)
                                                            }}
                                                        >
                                                            <p>{movie.movie_name} ( <span><b>Language :</b> {movie.language}</span>, <span><b>Time :</b> {movie.time}</span> )</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {
                                    selectedMovieName && sectionWiseBlock &&
                                    <div className="section_select_sec">
                                        <div className="dropdown_sec">
                                            <input type="text" value={selectedSection.sec_name} required />
                                            <div className="dropdown_btn" onClick={handleSectionsDropdown}>
                                                <p>{selectedSection.sec_name}</p>
                                                <span>Select Section</span>
                                                <i className={`fa-solid fa-angle-down ${sectionsDropdown? 'active':''}`}></i>
                                            </div>
                                            <div className={`dropdown ${sectionsDropdown? 'active':''}`}>
                                                <ul>
                                                    {
                                                        screenSections && screenSections.map((screenSection, i) => 
                                                            <li key={i}
                                                                onClick={() => {
                                                                    setSelectedSection(screenSection)
                                                                    setSelectedMovie({movie_name: tempSelectedMovie.movie_name, language: tempSelectedMovie.language, date: tempSelectedMovie.date, time: tempSelectedMovie.time})
                                                                    setPermittedSeatsAmount(screenSection.max_blocked_seat_no)
                                                                    handleBookedSeatsSectionWise(tempSelectedMovie.movie_name, tempSelectedMovie.date, tempSelectedMovie.time, screenSection.sec_name, screenSection.max_blocked_seat_no)
                                                                    setSectionsDropdown(false)
                                                                }}
                                                            >
                                                                {screenSection.sec_name}
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                checkPublish &&
                                <SeatLayout booking={false} theater={theater} bookedSeats={bookedSeats} selectedMovie={selectedMovie} selectedScreen={selectedScreen} sectionWiseBlock={sectionWiseBlock} selectedSection={selectedSection} permittedSeatsAmount={permittedSeatsAmount} refreshBookedSeats={refreshBookedSeats} />
                            }
                            {
                                !checkPublish && 
                                <div className="warning_popup_sec">
                                    <div className="warning_popup">
                                        <div className="icon">
                                            <img src="/images/warning.gif" alt="warning" />
                                        </div>
                                        <div className="content_sec">
                                            <p>You have not published <span>{selectedScreen ? selectedScreen.screen : ''}</span></p>
                                            <Link to="/panel/screen-settings">Publish Now</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </ScreenLayoutWrapper>

            {blockPermission && <WarningModal message={`You don't have permission to block seats. Please contact admin to enable this.`} />}
        </>
    );
}


export default SeatBlock;