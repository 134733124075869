import { useEffect, useState } from "react";
import { SettlementWrapper } from "../Styles/Settlement-Style";
import axios from "axios";
import { Fetch_Settlement_Records_URL } from "../API/Api";


function SettlementPage() {

    const [records, setRecords] = useState([]);

    useEffect(() => {
        const user = localStorage.getItem("User");
        axios.get(`${Fetch_Settlement_Records_URL}?name=${user}`)
        .then(res => {
            console.log(res);
            setRecords(res.data.settlements);
        })
        .catch(err => {
            console.log(err);
            setRecords([]);
        })
    }, []);

    return(
        <>
            <SettlementWrapper>
                <div className="wrapper_inner">
                    <div className="settle_table_sec">
                        <h3>Ticket Price Settlements</h3>
                        <div className="table_sec">
                            <table>
                                <thead>
                                    <th>Sl. No.</th>
                                    <th>Date</th>
                                    <th>Amount (Rs.)</th>
                                    <th>Status</th>
                                    <th>Invoice</th>
                                </thead>
                                <tbody>
                                    {
                                        records.length > 0 ? 
                                        records.map((record, i) => 
                                            <tr key={i}>
                                                <td>{i + 1}.</td>
                                                <td>{record.date}</td>
                                                <td>{parseFloat(record.total_ticket_price).toFixed(2)}</td>
                                                <td>
                                                    {record.ticket_price_settlement_status === "done" ? <span className="done">Paid</span> : <span className="pending">Pending</span>}
                                                </td>
                                                <td><a className={record.ticket_price_settlement_status === "done" ? '' : 'disable'}><i className="fa-solid fa-download"></i></a></td>
                                            </tr>
                                        )
                                        :
                                        <tr><p>No data available</p></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="settle_table_sec">
                        <h3>Commission Settlements</h3>
                        <div className="table_sec">
                            <table>
                                <thead>
                                    <th>Sl. No.</th>
                                    <th>Date</th>
                                    <th>Amount (Rs.)</th>
                                    <th>Status</th>
                                    <th>Invoice</th>
                                </thead>
                                <tbody>
                                    {
                                        records.length > 0 ? 
                                        records.map((record, i) => 
                                            <tr key={i}>
                                                <td>{i + 1}.</td>
                                                <td>{record.date}</td>
                                                <td>{parseFloat(record.total_theater_commission).toFixed(2)}</td>
                                                <td>
                                                    {record.commission_settlement_status === "done" ? <span className="done">Paid</span> : <span className="pending">Pending</span>}
                                                </td>
                                                <td><a className={record.commission_settlement_status === "done" ? '' : 'disable'}><i className="fa-solid fa-download"></i></a></td>
                                            </tr>
                                        )
                                        :
                                        <tr><p>No data available</p></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </SettlementWrapper>
        </>
    );
}


export default SettlementPage;