import { styled } from "@mui/material";


export const LoginWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 50px;
    background: linear-gradient(135deg, #E1E0EA 0%, #FFFF6C 40%, #FFC76C 70%, #FC6736 100%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(100px);
    }

    .login_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .heading {
            position: relative;

            h1 {
                position: relative;
                font-family: arista-pro-trial-bold;
                font-size: 45px;
                color: #000;

                span {
                    font-family: 'Oleo Script', cursive;
                }
            }
        }

        .form_box {
            position: relative;
            margin-top: 90px;
            width: 450px;
            height: 450px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 50px;
            transition: all 0.2s;

            &.active {
                height: 560px;
                transition: all 0.2s;
            }

            .square {
                position: absolute;
                backdrop-filter: blur(5px);
                box-shadow: 0 25px 45px rgba(0,0,0,0.1);
                border: 1px solid rgba(255,255,255,0.5);
                border-right: 1px solid rgba(255,255,255,0.2);
                border-bottom: 1px solid rgba(255,255,255,0.2);
                background: rgba(255,255,255,0.1);
                border-radius: 10px;
                animation: sqanimation 6s linear infinite;
                animation-delay: calc(-1s * var(--i));

                &:nth-child(1) {
                    top: -10px;
                    right: -20px;
                    width: 100px;
                    height: 100px;
                }

                &:nth-child(2) {
                    top: 200px;
                    left: -100px;
                    width: 120px;
                    height: 120px;
                    z-index: 2;
                }

                &:nth-child(3) {
                    bottom: 50px;
                    right: -50px;
                    width: 80px;
                    height: 80px;
                    z-index: 2;
                }

                &:nth-child(4) {
                    bottom: -15px;
                    left: 100px;
                    width: 50px;
                    height: 50px;
                }

                &:nth-child(5) {
                    top: -20px;
                    left: -40px;
                    width: 60px;
                    height: 60px;
                }

                @keyframes sqanimation
                {
                    0%,100%
                    {
                        transform: translateY(-10px);
                    }
                    50%
                    {
                        transform: translateY(30px);
                    }
                }
            }

            .forms_inner {
                position: relative;
                width: 100%;
                height: 100%;

                form {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    background: rgba(255, 255, 255, 0.171);
                    border-radius: 10px;
                    backdrop-filter: blur(5px);
                    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
                    border: 1px solid rgba(255,255,255,0.5);
                    border-right: 1px solid rgba(255,255,255,0.2);
                    border-bottom: 1px solid rgba(255,255,255,0.2);
                    padding: 30px;

                    &#login {
                        transform: scale(1);
                        transform-origin: top left;
                        transition: 0.4s;

                        &.active {
                            transform: scale(0);
                            transform-origin: top left;
                            transition: 0.4s;
                        }
                    }

                    &#register {
                        transform: scale(0);
                        transform-origin: bottom right;
                        transition: 0.4s;

                        &.active {
                            transform: scale(1);
                            transform-origin: bottom right;
                            transition: 0.4s;
                        }
                    }

                    .form_head {
                        position: relative;

                        h3 {
                            font-family: 'Lemonada', cursive;
                            font-size: 20px;
                            line-height: 1;
                            font-weight: 700;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: -10px;
                                left: 0;
                                width: 40px;
                                height: 3px;
                                background: #FC6736;
                                border-radius: 20px;
                            }
                        }
                    }

                    .form_input_sec {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-top: 25px;

                        .inputBox {
                            position: relative;
                            width: 100%;
                            margin-top: 20px;

                            input {
                                position: relative;
                                width: 100%;
                                padding: 8px 0;
                                background: transparent;
                                border: none;
                                border-bottom: 1px solid rgb(200, 200, 200);
                                outline: none;
                                color: #222;
                                font-size: 14px;
                                font-family: 'Poppins', sans-serif;

                                &:focus,
                                &:valid {
                                    border-bottom: 1px solid #FC6736;
                                }
                            }

                            label {
                                position: absolute;
                                top: 0;
                                left: 0;
                                padding: 8px 0;
                                pointer-events: none;
                                color: #555;
                                text-transform: uppercase;
                                font-size: 14px;  
                                font-family: 'Poppins', sans-serif;

                                span {
                                    position: relative;
                                    display: inline-flex;
                                    letter-spacing: 0.5px;
                                    transition: 0.2s ease-in-out;
                                }
                            }

                            input:focus ~ label span,
                            input:valid ~ label span {
                                color: #FC6736;
                                letter-spacing: 1px;
                                transform: translateY(-20px);
                                font-weight: 600;
                                font-size: 11px;
                            }

                            a {
                                position: absolute;
                                top: 0;
                                right: 0;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                color: #555;
                                font-size: 16px;
                            }

                            button {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                margin-top: 10px;
                                border: none;
                                background: #FC6736;
                                border-radius: 8px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 15px;
                                font-weight: 600;
                                color: #FFF;
                                text-transform: uppercase;
                                cursor: pointer;
                                letter-spacing: 0.7px;
                                transition: all 0.5s;

                                &:hover {
                                    box-shadow: 3px 8px 15px rgba(5, 5, 5, 0.342);
                                    transition: all 0.5s;
                                }
                            }
                        }

                        .redirect {
                            position: relative;
                            margin-top: 15px;

                            p {
                                font-size: 13.5px;
                                color: #444;

                                a {
                                    font-weight: 15px;
                                    font-weight: 500;
                                    color: #FC6736;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const RegisterWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 20px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        form {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .input_box {
                position: relative;
                width: 25%;
                margin-bottom: 30px;

                &.name {
                    width: 46%;
                }

                input {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    border: 2px solid #E1E0EA;
                    border-radius: 6px;
                    padding: 5px 25px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    line-height: 1;
                    color: #333;
                    outline: none;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 25px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    line-height: 1;
                    color: #555;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transition: 0.5s;
                }

                a {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    font-size: 16px;
                    font-weight: 500;
                    background: #02C0FF;
                    border: 2px solid #02C0FF;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFF;
                    cursor: pointer;
                    pointer-events: none;
                    opacity: 0.4;

                    &.active {
                        opacity: 1;
                        pointer-events: initial;
                    }

                    i {
                        font-size: 18px;
                        margin-right: 10px;
                    }
                }

                input:focus,
                input:valid  {
                    border: 2px solid #FC6736;
                    transition: 0.5s;
                }

                input:focus ~ span,
                input:valid ~ span{
                    top: 0%;
                    left: 15px;
                    font-size: 13px;
                    padding: 0px 8px;
                    background: #FFF;
                    color: #02C0FF;
                    font-weight: 500;
                    transition: 0.5s;
                }
            }

            .select_box_sec {
                position: relative;
                width: 25%;
                display: flex;
                flex-direction: column;

                .input_box {
                    position: relative;
                    width: 100%;
                    margin-bottom: 25px;
                }

                .select_box {
                    position: relative;
                    width: 100%;
                    margin-bottom: 25px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            pointer-events: none;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.8s;
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 2px 0;
                                padding: 13px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }

            .address_box {
                position: relative;
                width: 73%;

                textarea {
                    position: relative;
                    width: 100%;
                    height: 215px;
                    border: 2px solid #E1E0EA;
                    outline: none;
                    resize: none;
                    border-radius: 6px;
                    padding: 15px 25px;
                    font-size: 16px;
                    color: #333;
                }

                span {
                    position: absolute;
                    top: 20px;
                    left: 25px;
                    font-size: 16px;
                    line-height: 1;
                    color: #555;
                }

                textarea:focus,
                textarea:valid  {
                    border: 2px solid #FC6736;
                    transition: 0.5s;
                }

                textarea:focus ~ span,
                textarea:valid ~ span{
                    top: -6px;
                    left: 15px;
                    font-size: 13px;
                    padding: 0px 8px;
                    background: #FFF;
                    color: #02C0FF;
                    font-weight: 500;
                    transition: 0.5s;
                }
            }

            .form_btn {
                position: relative;
                width: 100%;
                margin-top: 40px;
                display: flex;
                justify-content: flex-end;

                button {
                    position: relative;
                    width: 200px;
                    height: 55px;
                    cursor: pointer;
                    background: #3FE44F;
                    border: 2px solid #3FE44F;
                    font-size: 17px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #FFF;
                    letter-spacing: 0.66px;
                    border-radius: 6px;
                }
            }
        }
    }
`;

export const StatusWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    overflow-y: auto;

    .wrapper_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .header_part {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 20px;
                font-weight: 600;
                color: #555;
            }

            a {
                position: relative;
                width: 200px;
                height: 55px;
                background: #02C0FF;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 6px;
                font-size: 17px;
                font-weight: 600;
                color: #FFF;

                i {
                    position: relative;
                    font-size: 20px;
                    margin-right: 10px;
                    transition: 0.6s;

                    &.rotate {
                        transform: rotate(360deg);
                        transition: 0.6s;
                    }
                }
            }
        }

        .status_part {
            position: relative;
            width: 100%;
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            padding-left: 15px;

            .status_progress_sec {
                position: relative;
                width: 100%;
                margin-bottom: 35px;

                .progress_inner {
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .dot {
                        position: relative;
                        width: 55px;
                        height: 55px;
                        border-radius: 50%;

                        span {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            background: #F0F0F0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            z-index: 5;

                            i {
                                font-size: 20px;
                                color: #FFF;
                                opacity: 0;

                                &:first-of-type {
                                    display: block;
                                }

                                &:last-of-type {
                                    display: none;
                                }
                            }
                        }

                        &:first-of-type {
                            &.complete span {
                                background: #FFC76C;

                                i {
                                    opacity: 1;
                                }
                            }
                            &.done span {
                                background: #3FE44F;

                                i {
                                    opacity: 1;
                                }
                            }
                        }

                        &.complete span {
                            background: #3FE44F;

                            i {
                                opacity: 1;
                            }
                        }

                        &.rejected span {
                            background: #F00 !important;

                            i {
                                opacity: 1;

                                &:first-of-type {
                                    display: none;
                                }

                                &:last-of-type {
                                    display: block;
                                }
                            }
                        }

                        &.active {
                            &::after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: rgba(63, 228, 79, 0.764);
                                border-radius: 50%;
                                opacity: 0.7;
                                animation: pulse 2s 1s ease-in-out infinite;
                            }
                            &::before{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: rgba(63, 228, 79, 0.764);
                                border-radius: 50%;
                                opacity: 0.7;
                                animation: pulse 2s ease-in-out infinite;
                            }

                            @keyframes pulse
                            {
                                100% {
                                    transform: scale(1.8);
                                    opacity: 0;
                                }
                            }
                        }

                        &.rejectActive {
                            &::after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: rgba(255, 0, 0, 0.764);
                                border-radius: 50%;
                                opacity: 0.7;
                                animation: pulse 2s 1s ease-in-out infinite;
                            }
                            &::before{
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: rgba(255, 0, 0, 0.764);
                                border-radius: 50%;
                                opacity: 0.7;
                                animation: pulse 2s ease-in-out infinite;
                            }

                            @keyframes pulse
                            {
                                100% {
                                    transform: scale(1.7);
                                    opacity: 0;
                                }
                            }
                        }

                        &:first-of-type {
                            &.active {
                                &::after{
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    background: rgba(255, 199, 108, 0.764);
                                    border-radius: 50%;
                                    opacity: 0.7;
                                    animation: pulse 2s 1s ease-in-out infinite;
                                }
                                &::before{
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    background: rgba(255, 199, 108, 0.764);
                                    border-radius: 50%;
                                    opacity: 0.7;
                                    animation: pulse 2s ease-in-out infinite;
                                }

                                @keyframes pulse
                                {
                                    100% {
                                        transform: scale(1.8);
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }

                    .bar {
                        position: relative;
                        width: 150px;
                        height: 5px;
                        background: #F0F0F0;
                        margin: 0px 10px;
                        border-radius: 25px;

                        &.complete {
                            background: #3FE44F;
                        }
                    }
                }
            }

            .status_comment_sec {
                position: relative;
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;

                .status_sec {
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    h5 {
                        position: relative;
                        font-size: 17px;
                        font-weight: 600;
                        color: #222;
                        line-height: 1;
                    }

                    p {
                        position: relative;
                        margin-left: 15px;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;

                        span {
                            font-weight: 500;
                            margin-right: 5px;
                        }
                    }
                }

                span {
                    position: relative;
                    font-size: 16px;
                    color: #555;

                    a {
                        position: relative;
                        margin-left: 5px;
                        font-weight: 500;
                        cursor: pointer;
                        color: #3FE44F;

                        i {
                            position: relative;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
    }
`;