import { styled } from "@mui/material";


export const SettlementWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 20px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .settle_table_sec {
            position: relative;
            width: 48%;
            display: flex;
            flex-direction: column;

            h3 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 16px;
                font-weight: 500;
                color: #000;
            }

            .table_sec {
                position: relative;
                width: 100%;
                margin-top: 20px;

                table {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    thead {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        display: flex;
                        background: #FC6736;
                        border-radius: 10px 10px 0 0 ;
                        border: 1px solid #FC6736;

                        th {
                            position: relative;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            font-family: 'Lemonada', cursive;
                            font-size: 14px;
                            color: #FFF;
                            font-weight: 600;
                            padding: 0px 25px;

                            &:nth-child(1) {
                                width: 15%;
                            }

                            &:nth-child(2) {
                                width: 22%;
                            }

                            &:nth-child(3) {
                                width: 25%;
                            }

                            &:nth-child(4) {
                                width: 20%;
                                justify-content: center;
                            }

                            &:nth-child(5) {
                                width: 18%;
                                justify-content: center;
                            }
                        }
                    }

                    tbody {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        max-height: 600px;
                        overflow-y: auto;
                        border: 1px solid #E1E0EA;
                        border-radius: 0 0 10px 10px;

                        tr {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            display: flex;

                            &:nth-of-type(even) {
                                background: rgba(2, 192, 255, 0.15);
                            }

                            td {
                                position: relative;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                padding: 0px 25px;
                                color: #444;
                                font-size: 15px;

                                &:nth-child(1) {
                                    width: 15%;
                                }

                                &:nth-child(2) {
                                    width: 22%;
                                }

                                &:nth-child(3) {
                                    width: 25%;
                                }

                                &:nth-child(4) {
                                    width: 20%;
                                    justify-content: center;
                                    font-weight: 500;

                                    .done {
                                        color: #3FE44F;
                                    }

                                    .pending {
                                        color: rgba(255, 0, 0, 0.764);
                                    }
                                }

                                &:nth-child(5) {
                                    width: 18%;
                                    justify-content: center;

                                    a {
                                        position: relative;
                                        font-size: 18px;
                                        color: #02C0FF;
                                        cursor: pointer;

                                        &.disable {
                                            opacity: 0.3;
                                            pointer-events: none;
                                        }
                                    }
                                }
                            }

                            p {
                                position: relative;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 15px;
                                color: rgb(137, 137, 137);
                            }
                        }
                    }
                }
            }
        }
    }
`;