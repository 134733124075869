import { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';



function TicketData() {

    const [chartWidth, setChartWidth] = useState(getChartWidth());

    function getChartWidth() {
        if (window.innerWidth > 1699) return 450;
        if (window.innerWidth < 1399 && window.innerWidth >= 450) return 420;
        if (window.innerWidth < 450) return 320;
        return 320; // Default for smaller screens
    }

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(getChartWidth());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [series, setSeries] = useState([
        { name: 'Online', data: 80 },
        { name: 'Offline', data: 25 }
    ]);

    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            position: 'bottom',
            horizontalAlign: "center",
            offsetY: 0,
        },
        colors: ['#02C0FF', '#FF33A1'],
        labels: series.map(item => item.name),
    });

    return(
        <>
            <div id="chart">
                <ReactApexChart  options={options} series={series.map(item => item.data)} type="donut" width={chartWidth} />
            </div>
            <div id="html-dist"></div>
        </>
    );
}


export default TicketData;