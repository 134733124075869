import { useContext, useEffect, useRef, useState } from "react";
import { RegisterWrapper, StatusWrapper } from "../Styles/Login-Style";
import states from '../Data/State-City.json';
import ScreenDetailModal from "../Modals/ScreenDetailsMondal";
import { DataContext } from "../Layouts/Data-Context";
import axios from "axios";
import { Req_Status_URL, Theater_Req_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function Register() {

    const {setSidebarReload} = useContext(DataContext);
    const [cities, setCities] = useState([]);

    const [userName, setUserName] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [stateDropdown, setStateDropdown] = useState(false);
    const [cityDropdown, setCityDropdown] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [screenInputs, setScreenInputs] = useState({
        name: '',
        screenNo: ''
    });
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pending, setPending] = useState(false);
    const [reject, setReject] = useState(false);
    const [process, setProcess] = useState(false);
    const [ready, setReady] = useState(false);
    const [pendingComplete, setPendingComplete] = useState(false);
    const [rejectComplete, setRejectComplete] = useState(false);
    const [confirmComplete, setConfirmComplete] = useState(false);
    const [processComplete, setProcessComplete] = useState(false);
    const [readyComplete, setReadyComplete] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [statusMessage, setStatusMessage] = useState('');

    const nameRef = useRef();
    const screenNoRef = useRef();
    const gstNoRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const addressRef = useRef();

    useEffect(() => {
        const username = localStorage.getItem("User");
        setUserName(username);
    }, []);

    useEffect(() => {
        const user = localStorage.getItem("User");

        const input = {
            userName: user
        }

        axios.post(Req_Status_URL, input, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            let status = res.data.statusMessage;
            console.log(res);

            if(status == "Pending"){
                setReject(false);
                setRejectComplete(false);
                setPending(true);
                setPendingComplete(true);
                setConfirmComplete(false);
                setProcess(false);
                setProcessComplete(false);
                setStatusMessage('<span style="color: #F00;">Pending</span>( Pending confirmation from Admin )');
                setShowForm(false);
            } else if(status == "Reject"){
                setReject(true);
                setRejectComplete(true);
                setPending(false);
                setPendingComplete(false);
                setConfirmComplete(false);
                setProcess(false);
                setProcessComplete(false);
                setStatusMessage(`<span style="color: #F00;">Rejected</span>( ${res.data.reason} )`);
                setShowForm(false);
            } else if(status == "Confirm"){
                setReject(false);
                setRejectComplete(false);
                setPending(false);
                setPendingComplete(true);
                setConfirmComplete(true);
                setProcess(false);
                setProcessComplete(false);
                setStatusMessage('<span style="color: #02C0FF;">Confirmed</span>( Your request is confirmed by Admin )');
                setShowForm(false);
            } else if(status == "Processing"){
                setReject(false);
                setRejectComplete(false);
                setPending(false);
                setPendingComplete(true);
                setConfirmComplete(true);
                setProcess(true);
                setProcessComplete(true);
                setStatusMessage('<span style="color: #02C0FF;">Processing</span>( Your request is under procress )');
                setShowForm(false);
            } else if(status == "Complete"){
                setReject(false);
                setRejectComplete(false);
                setPending(false);
                setPendingComplete(true);
                setConfirmComplete(true);
                setProcess(false);
                setProcessComplete(true);
                setReady(true);
                setReadyComplete(true);
                setStatusMessage('<span style="color: #3FE44F;">Completed</span>( Your request has been settled )');
                setShowForm(false);
            }
        })
        .catch(err => {
            console.log(err);
        })

    },[refresh]);

    const handleFormAppear = () => {
        setShowForm(true);
    };

    const handleStateDropdown = () => {
        setStateDropdown(!stateDropdown);
        setCityDropdown(false);
    };

    const handleCityDropdown = () => {
        setCityDropdown(!cityDropdown);
        setStateDropdown(false);
    };

    const openModal = () => {
        setShowModal(true);
        console.log(screenInputs);
    };

    const handleScreenInput = (e) => {
        setScreenInputs({...screenInputs, [e.target.name]: e.target.value});
    };

    const isScreenDetailsEmpty = () => {
        return !screenInputs.name || !screenInputs.screenNo;
    };

    const clearForm = () => {
        setScreenInputs({
            name: '',
            screenNo: ''
        });
        setSelectedState('');
        setSelectedCity('');
        setCities([]);

        nameRef.current.value = '';
        screenNoRef.current.value = '';
        cityRef.current.value = '';
        stateRef.current.value = '';
        addressRef.current.value = '';
    };

    const handleRefresh = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setRefresh(prev => !prev);
        }, 1000); 
    };

    function handleSubmit(e) {
        e.preventDefault();

        const inputs = {
            username: userName,
            name: nameRef.current.value,
            screenNo: screenNoRef.current.value,
            city: cityRef.current.value,
            state: stateRef.current.value,
            address: addressRef.current.value,
        }

        axios.post(Theater_Req_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res=> {
            let status = res.data.status;
            console.log(res);

            if(status == "200"){
                toast.success('Request sent successfully !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                localStorage.setItem("Theater", nameRef.current.value);
                clearForm();
                setShowForm(false);
                handleRefresh();
                setSidebarReload(true);
            }
        })
        .catch(err => {

            toast.error('Somthing went wrong !!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

        })
    }

    return(
        <>
            {
                showForm ? 
                (
                    <RegisterWrapper>
                        <div className="wrapper_inner">
                            <form onSubmit={handleSubmit}>
                                <div className="input_box name">
                                    <input type="text" name="name" ref={nameRef} onChange={handleScreenInput} required />
                                    <span>Theater Name</span>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="screenNo" ref={screenNoRef} onChange={handleScreenInput} required />
                                    <span>No. of Screens</span>
                                </div>
                                <div className="input_box">
                                    <a className={!isScreenDetailsEmpty() ? 'active' : ''} onClick={openModal}><i className="fa-solid fa-cloud-arrow-up"></i>Upload Screen Details</a>
                                </div>
                                <div className="select_box_sec">
                                    <div className="input_box">
                                        <input type="text" name="gstNo" ref={gstNoRef} onChange={handleScreenInput} required />
                                        <span>GST No.</span>
                                    </div>
                                    <div className="select_box">
                                        <input type="text" id="state" ref={stateRef} required />
                                        <div className="dropdown_btn" onClick={handleStateDropdown}>
                                            <p>{selectedState}</p>
                                            <span>State</span>
                                            <i className={`fa-solid fa-angle-down ${stateDropdown? 'active':''}`}></i>
                                        </div>
                                        <div className={`dropdown ${stateDropdown? 'active':''}`}>
                                            <ul>
                                                {
                                                    states.map(state => (
                                                        <li onClick={(e) => {
                                                            setSelectedState(state.name)
                                                            setStateDropdown(false)
                                                            document.getElementById('state').value = state.name
                                                            setCities(states.find(state => state.name === e.target.textContent).cities)
                                                        }}>{state.name}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="select_box">
                                        <input type="text" id="city" ref={cityRef} required />
                                        <div className="dropdown_btn" onClick={handleCityDropdown}>
                                            <p>{selectedCity}</p>
                                            <span>City</span>
                                            <i className={`fa-solid fa-angle-down ${cityDropdown? 'active':''}`}></i>
                                        </div>
                                        <div className={`dropdown ${cityDropdown? 'active':''}`}>
                                            <ul>
                                                { 
                                                    cities.map(city => (
                                                        <li onClick={(e) => {
                                                            setSelectedCity(city.name)
                                                            setCityDropdown(false)
                                                            document.getElementById('city').value = city.name
                                                        }}>{city.name}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="address_box">
                                    <textarea name="address" ref={addressRef} required></textarea>
                                    <span>Address</span>
                                </div>
                                <div className="form_btn">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </RegisterWrapper>
                ) : (
                    <StatusWrapper>
                        <div className="wrapper_inner">
                            <div className="header_part">
                                <h3>You have already submiited request for your theater</h3>
                                <a onClick={handleRefresh}><i className={`fa-solid fa-arrows-rotate ${loading ? 'rotate':''}`}></i>Refresh</a>
                            </div>
                            <div className="status_part">
                                <div className="status_progress_sec">
                                    <div className="progress_inner">
                                        <div className={`dot ${pendingComplete ? 'complete' : ''} ${pending ? 'active' : 'done'} ${reject ? 'rejected' : ''} ${rejectComplete ? 'rejectActive' : ''}`}><span><i className={`fa-solid fa-${pending ? 'exclamation' : 'check'}`}></i><i className="fa-solid fa-xmark"></i></span></div>
                                        <div className={`bar ${confirmComplete ? 'complete' : ''}`}></div>
                                        <div className={`dot ${processComplete ? 'complete' : ''} ${process ? 'active' : ''}`}><span><i className="fa-solid fa-check"></i><i className="fa-solid fa-xmark"></i></span></div>
                                        <div className={`bar ${readyComplete ? 'complete' : ''}`}></div>
                                        <div className={`dot ${readyComplete ? 'complete' : ''} ${ready ? 'active' : ''}`}><span><i className="fa-solid fa-check"></i><i className="fa-solid fa-xmark"></i></span></div>
                                    </div>
                                </div>
                                <div className="status_comment_sec">
                                    <div className="status_sec">
                                        <h5>Status :</h5>
                                        <p id="status-meessage" dangerouslySetInnerHTML={{ __html: statusMessage }}></p>
                                    </div>
                                    {reject && <span>Please, resubmit your form. <a onClick={handleFormAppear}>Click Here<i class="fa-solid fa-angles-right"></i></a></span>}
                                    {ready && <span>Please, go to <a>Screen Settings</a> page and publish your theater screens.</span>}
                                </div>
                            </div>
                        </div>
                    </StatusWrapper>
                )
            }

            <ScreenDetailModal showModal={showModal} setShowModal={setShowModal} screenInputs={screenInputs} userName={userName} />
        </>
    );
}


export default Register;