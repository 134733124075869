import { styled } from "@mui/material";


export const DashboardWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;

    .dashboard_inner {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;

        .dashboard_box_sec {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .box {
                position: relative;
                width: 24%;
                height: 100px;
                border-radius: 10px;
                padding: 25px 30px;
                cursor: pointer;
                transition: all 0.6s ease-in-out;
                text-decoration: none;

                &:hover {
                    box-shadow: 8px 12px 20px rgba(50,50,50,0.4),
                                -3px -3px 8px rgba(100,100,100,0.2)
                }

                &:nth-child(1) {
                    background: linear-gradient(to bottom, #fafafa 00%, rgba(28, 66, 255, 0.5) 100%);
                }

                &:nth-child(2) {
                    background: linear-gradient(to bottom, #fafafa 00%, rgba(63, 228, 79, 0.7) 100%);
                }

                &:nth-child(3) {
                    background: linear-gradient(to bottom, #fafafa 00%, rgba(2, 192, 255, 0.562) 100%);
                }

                &:nth-child(4) {
                    background: linear-gradient(to bottom, #fafafa 00%, #FFC76C 100%);
                }

                .box_inner {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;

                    .inner_item {
                        position: relative;
                        width: calc(100% - 70px);
                        display: flex;
                        flex-direction: column;

                        h4 {
                            position: relative;
                            font-family: 'Lemonada', cursive;
                            font-size: 17px;
                            font-weight: 600;
                            color: #222;
                        }

                        p {
                            position: relative;
                            font-family: 'Poppins', sans-serif;
                            line-height: 1;
                            font-size: 17px;
                            margin-top: 10px;
                            font-weight: 500;
                            color: #444;
                        }
                    }

                    .icon {
                        position: relative;
                        width: 70px;
                        display: flex;
                        justify-content: flex-end;

                        i {
                            position: relative;
                            font-size: 40px;
                            color: #333;
                        }
                    }
                }
            }

            @media (max-width: 1599px) {
                .box {
                    width: 32%;
                    margin-bottom: 25px;
                }
            }

            @media (max-width: 1299px) {
                .box {
                    width: 48.5%;
                    transition: all 0.6s ease-in-out;
                }

                &.expand {
                    .box {
                        width: 32%;
                        transition: all 0.6s ease-in-out;
                    }
                }
            }

            @media (max-width: 1099px) {
                &.expand {
                    .box {
                        width: 48.5%;
                        transition: all 0.6s ease-in-out;
                    }
                }
            }

            @media (max-width: 991px) {
                .box {
                    width: 100%;
                    transition: all 0.6s ease-in-out;
                }
                &.expand {
                    .box {
                        width: 48.5%;
                        transition: all 0.6s ease-in-out;
                    }
                }
            }

            @media (max-width: 768px) {
                .box {
                    width: 48.5%;
                }
            }

            @media (max-width: 678px) {
                .box {
                    width: 100%;
                }
            }

            @media (max-width: 450px) {
                .box {
                    .box_inner {
                        .icon {
                            i {
                                font-size: 35px;
                            }
                        }
                    }
                }
            }
        }

        .superadmin_graph_sec {
            position: relative;
            margin-top: 60px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .user_graph {
                position: relative;
                width: 65%;
                display: flex;
                flex-direction: column;

                h4 {
                    position: relative;
                    font-family: arista-pro-alt-semibold;
                    font-size: 22px;
                    letter-spacing: 0.66px;
                    color: #444;
                }

                .graph_box {
                    position: relative;
                    margin-top: 20px;
                    width: 100%;
                    height: 350px;
                    background: #FFF;
                    border-radius: 10px;
                    padding: 25px;
                    padding-left: 15px;
                    box-shadow: 8px 12px 20px rgba(50,50,50,0.2),
                                -5px -5px 15px rgba(125,125,125,0.2);
                }
            }

            .movie_graph {
                position: relative;
                width: 32%;

                h4 {
                    position: relative;
                    font-family: arista-pro-alt-semibold;
                    font-size: 22px;
                    letter-spacing: 0.66px;
                    color: #444;
                }

                .graph_box {
                    position: relative;
                    margin-top: 20px;
                    width: 100%;
                    height: 350px;
                    background: #FFF;
                    border-radius: 10px;
                    display: flex;
                    padding: 30px;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 8px 12px 20px rgba(50,50,50,0.2),
                                -5px -5px 15px rgba(125,125,125,0.2);
                }
            }

            @media (max-width: 1599px) {
                margin-top: 25px;
            }

            @media (max-width: 1399px) {
                .user_graph {
                    width: 100%;
                    margin-bottom: 35px;
                }
                .movie_graph {
                    width: 400px;
                }
            }

            @media (max-width: 991px) {
                .user_graph {
                    h4 {
                        font-size: 20px;
                    }

                    .graph_box {
                        padding: 0;
                        padding-top: 5px;
                        padding-right: 5px;
                        height: 320px;
                    }
                }
                .movie_graph {
                    width: 100%;
                    h4 {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .dashboard_message {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        img {
            position: relative;
            width: 350px;
        }

        h3 {
            position: relative;
            margin-top: 35px;
            font-size: 22px;
            font-weight: 600;
            color: rgb(150, 150, 150);

            a {
                text-decoration: none;
                font-family: 'Lemonada', cursive;
                margin: 0 5px;
            }
        }
    }
`;