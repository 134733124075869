import { useEffect, useState } from "react";
import { ScreenSettingWrapper } from "../Styles/Screens-Style";
import axios from "axios";
import WarningModal from "../Modals/WarningModal";
import { Add_Section_Price_URL, Commission_Settlement_Check_URL, Fetch_Screens_URL, Publish_SCreen_URL } from "../API/Api";
import { Link } from "react-router-dom";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function ScreenSetting() {

    const [noRegeisterShow, setNoRegeisterShow] = useState(true);
    const [RegeisteredShow, setRegeisteredShow] = useState(false);
    const [screenDropdown, setScreenDropdown] = useState(false);
    const [sectionDropdown, setSectionDropdown] = useState(false);
    const [screens, setScreens] = useState([]);
    const [selectedScreen, setselectedScreen] = useState([]);
    const [selectScreen, setSelectScreen] = useState('');
    const [selectSection, setSelectSection] = useState('');
    const [selectSectionId, setSelectSectionId] = useState('');
    const [sections, setSections] = useState([]);
    const [reload, setReload] = useState(false);
    const [price, setPrice] = useState('');
    const [publishShow, setPublishShow] = useState(false);
    const [commissionSettled, setCommissionSettled] = useState(false);


    useEffect(() => {
        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Screens_URL}?user=${user}`)
        .then(res => {
            // console.log(res);
            let status = res.data.status;

            if(status == "202"){
                setNoRegeisterShow(true);
                setRegeisteredShow(false);
            } else if(status == "200"){
                setNoRegeisterShow(false);
                setRegeisteredShow(false);
                setScreens(res.data.screens);
                console.log(selectedScreen);

                if(selectedScreen) {
                    if(selectedScreen.prices == "not-set" && selectedScreen.price_null_count == "0"){
                        setPublishShow(true);
                    } else {
                        setPublishShow(false);
                    }
                } else {
                    setPublishShow(false);
                }
            }
        })
        .catch(err => {
            // console.log(err);
            setNoRegeisterShow(false);
            setRegeisteredShow(true);
        })

    }, [reload]);

    useEffect(() => {
        const user = localStorage.getItem("User");
        axios.get(`${Commission_Settlement_Check_URL}?user=${user}`)
        .then(res => {
            // console.log(res);
            setCommissionSettled(true);
        })
        .catch(err => {
            // console.log(err);
            setCommissionSettled(false);
        })
    }, []);


    const handleRefresh = () => {
        setTimeout(() => {
            setReload(prev => !prev);
        }, 1000); 
    };



    const handleScreenDropdown = () => {
        setScreenDropdown(!screenDropdown);
    };

    const handleSectionDropdown = () => {
        setSectionDropdown(!sectionDropdown);
    };

    const handlePriceInput = (e) => {
        setPrice(e.target.value);
    };

    const handlePriceSubmit = (e) => {
        e.preventDefault();

        const inputs = {
            screen_id: selectSectionId,
            name: selectSection,
            price: price
        }

        axios.post(Add_Section_Price_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);

            toast.success(`Price added to ${selectSection} section of ${selectScreen}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            if(res.data.price_null_count == "0"){
                setPublishShow(true);
            } else {
                setPublishShow(false);
            }
            setSelectSection('');
            setSelectSectionId('');
            setPrice('');
        })
        .catch(err => {
            console.log(err);
        })
    };

    const handlePublishScreen = (e) => {
        e.preventDefault();

        const inputs = {
            screen_id: selectedScreen.screen_id,
            screen: selectedScreen.screen,
            prices: 'set'
        }

        axios.post(Publish_SCreen_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log(res);
            toast.success(`${selectScreen} has been Published !!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setSelectScreen('');
            setSelectSection([]);
            handleRefresh();
        })
        .catch(err => {
            console.log(err);
        })
    };

    return(
        <>
            <ScreenSettingWrapper>
                {
                    noRegeisterShow && 
                    <div className="message">
                        <p>You haven't registered your theater yet. <Link to="/panel/theater-register">Register Now</Link></p>
                    </div>
                }

                {
                    RegeisteredShow && 
                    <div className="message"><p>Yours screens are not ready to use. Please check your request progress <Link to="/panel/theater-register">Check Now</Link>.</p></div>
                }
                {
                    !noRegeisterShow && !RegeisteredShow &&
                    <div className="wrapper_inner">
                        <siv className="top_message">
                            <h4>Please set prices for every sections of each screens to publish that screen. This is a one time process, further you can update your prices.</h4>
                        </siv>
                        <div className="inner_top">
                            <div className="screen_select_sec">
                                <div className="icon"><img src="/images/screen.png" alt="icon" /></div>
                                <div className="dropdown_sec">
                                    <input type="text" value={selectScreen} id="screen" required />
                                    <div className="dropdown_btn" onClick={handleScreenDropdown}>
                                        <p>{selectScreen}</p>
                                        <span>Select Screen</span>
                                        <i className={`fa-solid fa-angle-down ${screenDropdown? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${screenDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                screens.map((screen, index) => 
                                                    <li key={index}
                                                        onClick={() => {
                                                            setSelectScreen(screen.screen)
                                                            setselectedScreen(screen)
                                                            handleRefresh()
                                                            setScreenDropdown(false)
                                                            setSections(screen.seat_layouts)
                                                        }}
                                                    >{screen.screen}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="price_set_sec">
                                <div className="select_box">
                                    <input type="text" value={selectSection} required />
                                    <div className="dropdown_btn" onClick={handleSectionDropdown}>
                                        <p>{selectSection}</p>
                                        <span>Select Section</span>
                                        <i className={`fa-solid fa-angle-down ${sectionDropdown? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${sectionDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                sections && sections.map((section, index) => 
                                                    <li key={index}
                                                        onClick={() => {
                                                            setSelectSection(section.sec_name)
                                                            setSelectSectionId(section.screen_id)
                                                            setSectionDropdown(false)
                                                        }}
                                                    >{section.sec_name}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="input_box">
                                    <input type="text" value={price} onChange={handlePriceInput} required />
                                    <span>Price</span>
                                </div>
                                <div className="button_box">
                                    <a onClick={handlePriceSubmit}><span><i className="fa-solid fa-plus"></i>Add</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="inner_content">
                            {
                                screens.map((screen, index) => 
                                <div className="screen_price_box" key={index}>
                                    <h5>{screen.screen}</h5>
                                    <div className="price_box_sec">
                                        {
                                            (screen.seat_layouts).map((seat_layout, i) => 
                                                <li key={i}>
                                                    <p>{seat_layout.sec_name} :</p>
                                                    {seat_layout.price && <span>Rs. {seat_layout.price}/-</span>}
                                                </li>
                                            )
                                        }
                                    </div>
                                </div>
                                )
                            }
                        </div>
                        {
                            publishShow && 
                            <div className="inner_button">
                                <a onClick={handlePublishScreen}><span><i className="fa-solid fa-upload"></i>Publish {selectedScreen.screen}</span></a>
                            </div>
                        }
                    </div>
                }
            </ScreenSettingWrapper>

            { !noRegeisterShow && !RegeisteredShow && !commissionSettled && <WarningModal message={`Commission is not settled against your theater. Please wait or contact admin to settle your commision, otherwise you can't publish your screen(s).`} /> }
        </>
    );
}


export default ScreenSetting;